import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Conservatee",
      content: (
        <>
          <p>
            A conservatee is someone the court has determined is legally
            incapacitated and unable to make personal and/or financial
            decisions.
          </p>
        </>
      ),
    },
    {
      title: "Conservator",
      content: (
        <>
          <p>
            A conservator is a person appointed by a court who is authorized to
            make personal and/or financial decisions on behalf of a legally
            incapacitated person (the conservatee).
          </p>
        </>
      ),
    },
    {
      title: "Conservatorship of the Estate",
      content: (
        <>
          <p>
            This refers to a conservator who is managing a conservatee’s
            financial affairs.
          </p>
        </>
      ),
    },
    {
      title: "Conservatorship of the Person",
      content: (
        <>
          <p>
            This is a conservatorship where a conservator manages a
            conservatee’s medical and personal care decisions.
          </p>
        </>
      ),
    },
    {
      title: "General Conservatorship",
      content: (
        <>
          <p>
            A general conservatorship is for an adult who lacks the mental
            capacity to manage their personal and/or financial affairs due to a
            major neurocognitive disorder (such as Dementia or Alzheimer’s
            disease).
          </p>
        </>
      ),
    },
    {
      title: "Limited Conservatorship",
      content: (
        <>
          <p>
            A limited conservatorship is a type of conservatorship for adults
            who are developmentally disabled or suffered a brain injury prior to
            the age of 18. A limited conservator’s duty is to help the limited
            conservatee develop maximum self-reliance and independence.
          </p>
          <p>
            A limited conservator may ask the court to give you the following 7
            powers:
          </p>
          <ol className="pl-4 text-sm">
            <li>
              <p> 1). Fix the conservatee’s residence or dwelling</p>
            </li>
            <li>
              <p> 2). Access the conservatee’s confidential records or paper</p>
            </li>
            <li>
              <p>
                3). Consent or withhold consent to marriage on behalf of the
                conservatee
              </p>
            </li>
            <li>
              <p> 4). Enter into contracts on behalf of the conservatee</p>
            </li>
            <li>
              <p>
                5). Give or withhold medical consent on behalf of the
                conservatee
              </p>
            </li>
            <li>
              <p>
                6). Select the conservatee’s social and sexual contacts and
                relationships
              </p>
            </li>
            <li>
              <p> 7). Make decisions to educate the conservatee</p>
            </li>
          </ol>
        </>
      ),
    },
    {
      title: "Probate Conservatorship",
      content: (
        <>
          <p>
            This is a conservatorship proceeding held in the Probate Division of
            a court (vs. the Mental Health Division).
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Conservatorship Lawyer San Diego | Brierton, Jones & Jones"
        description="Do you need a top conservatorship lawyer in San Diego? Brierton, Jones & Jones, LLP connects you with what you need. Schedule a consultation today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pt-12 pb-6 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  COURT-APPOINTED CARE AND DECISION MAKING
                </div>
                <h1>San Diego Conservatorship Lawyer</h1>
                <p>
                  When a loved one is physically or mentally unable to take care
                  of themselves, a conservatorship will allow you to legally
                  handle their affairs. It can be a tough decision, and our
                  conservatorship lawyers can help you evaluate whether this
                  best fits your situation.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/6.0 Conservatorship/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>What is a Conservatorship?</h2>
                <p>
                  In a conservatorship, a court appoints a person (or persons)
                  to manage the affairs of another adult who’s been legally
                  determined to be unable to care for themselves.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>When Should a Conservator Be Appointed?</h2>
                <p>
                  Conservatorship is common for the elderly who require
                  assistance because of their age but can also be put in place
                  for other adults.
                </p>
                <p>
                  For example, if a person has a developmental disability, is in
                  a coma, has suffered a serious injury, or is experiencing a
                  decline from a disease, a conservatorship can be warranted.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>What Are a Conservator’s Responsibilities?</h2>
                <p>
                  An appointed conservator has a legal duty to responsibly
                  oversee their loved one’s finances, personal care, or both, as
                  defined by the terms of the conservatorship.
                </p>
                <p>
                  The responsibility typically lasts until the person whose
                  affairs are being handled (the conservatee) dies or is able to
                  once again take care of themselves.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  If you’re considering a conservatorship to help a loved one,
                  here are some of the common terms to familiarize yourself
                  with:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={3} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="Meet with one of our San Diego conservatorship lawyers to discuss whether it’s right for your loved one. We’re happy to share our expertise to help you make a decision." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/04 Conservatorship.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/04 Conservatorship.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
